<template>
  <b-container fluid class="search-content">
    <city-plans @loadMore="loadMore" :data="plans" />
    <p class="mt-4">يقوم فريق العمل بتحديث البيانات بشكل دوري. اذا لم تكن المدينة المطلوب البحث فيها من ضمن الاختيارات يرجى مراجعة التطبيق لاحقاً</p>
  </b-container>
</template>
<script>
import CityPlans from '@/components/CityPlans.vue'

export default {
  title() {
    return `Mokhatat - ${this.city}`
  },
  data() {
    return {
    }
  },
  watch: {
  },
  components: {
    CityPlans
  },
  computed: {
    currentPage() {
      return this.$store.state.plan.meta.current_page
    },
    pagination() {
      return this.$store.state.plan.meta.per_page
    },
    plans() {
      return this.$store.state.plan.plans
    },
    city() {
      if (!this.plans.length) return ""
      return this.plans[0]["city"]["name"]
    },
  },
  methods: {
    fetchPlans() {
      let loader = this.$loading.show({opacity: 0.9})
      const payload = {
        meta: {
          page: 1,
          paginate: this.pagination,
          city_id: this.$route.params.cityId
        }
      }

      this.$store.dispatch("plan/fetchPlans", payload)
      .then(() => {
        loader.hide()
      })
    },
    plan(val) {
      let planIndex = this.$store.state.plan.plans.findIndex(plan => plan.id === val)
      return this.$store.state.plan.plans[planIndex]
    },
    loadMore() {
      let loader = this.$loading.show({opacity: 0.9})
      const payload = {
        meta: {
          page: this.currentPage + 1,
          paginate: this.pagination,
          city_id: this.$route.params.cityId
        }
      }
      this.$store.dispatch("plan/loadMorePlans", payload)
      .then(() => {
        loader.hide()
      })
    }
  },
  created() {
    this.$store.dispatch("city/fetchCity", this.$route.params.cityId)
    this.fetchPlans()
    if(this.$route.query.share_id != undefined && this.$route.query.plan != undefined) {
      this.$store.dispatch("plan/recordPlanView", this.$route.query.share_id)
    }
  }
}
</script>

<style lang="scss">
#map {
  width: 100%;
  height: 100vh;
}
</style>
