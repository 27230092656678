<template>
<div>
<b-row>
  <b-col class="text-right">
    <b-badge pill class="pd-10 m-width-100 map-pill" @click="goBack" variant="dark">{{ city }} <b-icon icon="pencil-fill"></b-icon></b-badge>
  </b-col>
</b-row>
<b-row class="mt-4 m-height-scroll font-sfns">
  <b-col>
    <router-link v-for="item in data" :key="item.id" :to="{'name': 'city', params:{'cityId': item.city.id, 'plan': item}, query:{'plan': item.planNo}}">
     <b-badge pill class="pd-10 m-badge-outline m-width-100 map-pill d-block" variant="light">{{ item.planNo }} - {{ item.district }}</b-badge>
    </router-link>
  </b-col>
</b-row>
<b-row v-if="hideLoad" class="mt-4">
  <b-col>
    <b-button pill class="pd-10 width-150 map-pill d-block mx-auto" @click="loadMore" variant="dark">المزيد</b-button>
  </b-col>
</b-row>
</div>
</template>

<script>
export default {
  name: 'CityPlans',
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      map: require("@/assets/images/icons/map_box.png"),
      width: "28px"
    }
  },
  computed: {
    city() {
      if (!this.data.length) return ""
      return this.data[0]["city"]["name"]
    },
    hideLoad() {
      if ((this.$store.state.plan.meta.total - this.$store.state.plan.meta.to) > 0)
        return true
      return false
    }
  },
  methods: {
    goBack() {
      this.$router.push('/home')
    },
    loadMore() {
      this.$emit("loadMore")
    }
  }
}
</script>
